var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.temTitle,
        visible: _vm.dialogVisible,
        width: "1160px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "3%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11.5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入模板名称",
                              disabled: _vm.temTitle !== "新增价格模板",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12.5 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { label: "通知邮箱", prop: "noticeEmail" },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "email-popover",
                                placement: "bottom",
                                width: "560",
                                disabled:
                                  _vm.temTitle === "模板详情" ? true : false,
                                "visible-arrow": false,
                                trigger: "click",
                              },
                              model: {
                                value: _vm.popover,
                                callback: function ($$v) {
                                  _vm.popover = $$v
                                },
                                expression: "popover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "email-content" },
                                [
                                  _c("BaseMutiInput", {
                                    ref: "baseMutiInput",
                                    attrs: { default: _vm.form.notifyEmail },
                                    model: {
                                      value: _vm.form.noticeEmail,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "noticeEmail", $$v)
                                      },
                                      expression: "form.noticeEmail",
                                    },
                                  }),
                                  _c("div", { staticClass: "footers" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cancel flex_c_c",
                                        on: { click: _vm.cancel1 },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ok flex_c_c",
                                        on: { click: _vm.ok1 },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "cusInput",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._l(
                                    _vm.form.noticeEmail
                                      .filter((a) => a !== "")
                                      .slice(0, 2),
                                    function (tag) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: tag,
                                          attrs: {
                                            type: "info",
                                            closable:
                                              _vm.temTitle !== "模板详情",
                                            "disable-transitions": false,
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.handleClose(tag)
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(tag) + " ")]
                                      )
                                    }
                                  ),
                                  _vm.notifyEmailLength &&
                                  _vm.temTitle !== "模板详情"
                                    ? _c(
                                        "div",
                                        { staticClass: "num-remain flex_c_c" },
                                        [
                                          _vm._v(
                                            " +" +
                                              _vm._s(
                                                _vm.form.noticeEmail
                                                  .filter((a) => a !== "")
                                                  .slice(2).length
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm.notifyEmailLength &&
                                      _vm.temTitle === "模板详情"
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "280",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "email-box" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "email-header flex_c_c",
                                                },
                                                [_vm._v("通知邮箱")]
                                              ),
                                              _c(
                                                "ul",
                                                { staticClass: "email-body" },
                                                _vm._l(
                                                  _vm.form.noticeEmail
                                                    .filter((a) => a !== "")
                                                    .slice(2),
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value: 238,
                                                            expression: "238",
                                                          },
                                                        ],
                                                        key: item,
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "num-remain flex_c_c",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [_vm._v("···")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4.8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提单类型", prop: "mblType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.temTitle !== "新增价格模板",
                              },
                              on: { change: _vm.mblTypeChange },
                              model: {
                                value: _vm.form.mblType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mblType", $$v)
                                },
                                expression: "form.mblType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "海运", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: { label: "空运", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4.8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处理方式", prop: "pickUpType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.temTitle !== "新增价格模板",
                              },
                              model: {
                                value: _vm.form.pickUpType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pickUpType", $$v)
                                },
                                expression: "form.pickUpType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "直送", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "回仓", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4.8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "港口/机场", prop: "transportName" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "请输入港口/机场",
                                filterable: "",
                                disabled: _vm.temTitle !== "新增价格模板",
                              },
                              model: {
                                value: _vm.form.transportName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "transportName", $$v)
                                },
                                expression: "form.transportName",
                              },
                            },
                            _vm._l(_vm.transportOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4.8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效日期", prop: "effectiveDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "date",
                              placeholder: "请选择",
                              "value-format": "yyyy-MM-dd",
                              disabled: _vm.temTitle === "模板详情",
                              "picker-options": _vm.pickerOptions1,
                            },
                            model: {
                              value: _vm.form.effectiveDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "effectiveDate", $$v)
                              },
                              expression: "form.effectiveDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4.8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "失效日期", prop: "elapsedDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "date",
                              placeholder: "请选择",
                              "value-format": "yyyy-MM-dd",
                              disabled: _vm.temTitle === "模板详情",
                              "picker-options": _vm.pickerOptions2,
                            },
                            model: {
                              value: _vm.form.elapsedDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "elapsedDate", $$v)
                              },
                              expression: "form.elapsedDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showBaseTab
                ? _c(
                    "div",
                    { staticClass: "tab" },
                    [
                      _c("div", { staticClass: "line" }),
                      _c("BaseTab", {
                        staticStyle: { "z-index": "1" },
                        attrs: {
                          tabItems: _vm.tabItems,
                          propWidth: 490,
                          active: _vm.active,
                        },
                        on: { tabChange: _vm.tabChange },
                      }),
                      _vm.active && _vm.temTitle === "新增价格模板"
                        ? _c("div", { staticClass: "basetab_opt flex_c_c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "import flex_c_c",
                                on: { click: _vm.importFile },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/import.png"),
                                  },
                                }),
                                _vm._v(" 导入 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "download flex_c_c",
                                on: { click: _vm.download },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/download-1.png"),
                                  },
                                }),
                                _vm._v(" 下载模板 "),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-row", [
                _c(
                  "div",
                  { staticClass: "data" },
                  [
                    _c(
                      "BaseTable",
                      {
                        key: _vm.active,
                        attrs: {
                          tableData: {
                            ..._vm.tableData,
                            fileds: _vm.fileds,
                          },
                          height: "360",
                          isPager: false,
                          border: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "feeTypeId",
                            fn: function (row) {
                              return [
                                _vm.temTitle === "新增价格模板"
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          filterable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.feeTypeChaneg(
                                              row.tIndex,
                                              row.scope
                                            )
                                          },
                                        },
                                        model: {
                                          value: row.scope.feeTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row.scope,
                                              "feeTypeId",
                                              $$v
                                            )
                                          },
                                          expression: "row.scope.feeTypeId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.feeTypeOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatterFeeType")(
                                              row.scope.feeTypeId,
                                              _vm.feeTypeOptions
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "feeNameId",
                            fn: function (row) {
                              return [
                                _vm.temTitle === "新增价格模板"
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          filterable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.feeNameChange(row.scope)
                                          },
                                        },
                                        model: {
                                          value: row.scope.feeNameId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row.scope,
                                              "feeNameId",
                                              $$v
                                            )
                                          },
                                          expression: "row.scope.feeNameId",
                                        },
                                      },
                                      _vm._l(
                                        row.scope.feeNameList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatterFeeName")(
                                              row.scope.feeNameId,
                                              row.scope.feeTypeId,
                                              _vm.feeTypeOptions2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "warehouseCode",
                            fn: function (row) {
                              return [
                                _vm.temTitle !== "模板详情"
                                  ? _c("el-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: row.scope.warehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row.scope,
                                            "warehouseCode",
                                            $$v
                                          )
                                        },
                                        expression: "row.scope.warehouseCode",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.scope.warehouseCode) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "unitPrice",
                            fn: function (row) {
                              return [
                                _vm.temTitle !== "模板详情"
                                  ? _c("el-input", {
                                      attrs: {
                                        oninput:
                                          "value=value.replace(/[^0-9.]/g, '')",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: row.scope.unitPrice,
                                        callback: function ($$v) {
                                          _vm.$set(row.scope, "unitPrice", $$v)
                                        },
                                        expression: "row.scope.unitPrice",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(row.scope.unitPrice) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "autoTrigger",
                            fn: function (row) {
                              return [
                                _vm.temTitle !== "模板详情"
                                  ? _c("el-switch", {
                                      attrs: {
                                        "active-text": row.scope.autoTrigger
                                          ? "是"
                                          : "否",
                                      },
                                      model: {
                                        value: row.scope.autoTrigger,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row.scope,
                                            "autoTrigger",
                                            $$v
                                          )
                                        },
                                        expression: "row.scope.autoTrigger",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.scope.autoTrigger ? "是" : "否"
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "remarks",
                            fn: function (row) {
                              return [
                                _vm.temTitle === "新增价格模板"
                                  ? _c("el-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: row.scope.remarks,
                                        callback: function ($$v) {
                                          _vm.$set(row.scope, "remarks", $$v)
                                        },
                                        expression: "row.scope.remarks",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(row.scope.remarks) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "opt",
                            fn: function (row) {
                              return [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  attrs: { title: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRow(row.tIndex)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "opt-header" }, [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            attrs: { title: "新增" },
                            on: { click: _vm.add },
                          }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.temTitle !== "模板详情"
        ? _c(
            "span",
            {
              staticClass: "footers",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "ok flex_c_c",
                  on: {
                    click: function ($event) {
                      return _vm.ok("form")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ]
          )
        : _vm._e(),
      _c("ImportPriceTemplateDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          feeTypeOptions: _vm.feeTypeOptions,
          feeTypeOptions2: _vm.feeTypeOptions2,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok2,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }