<template>
  <el-dialog
    v-drag
    title="导入"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="upload">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          list-type="picture"
          :http-request="httpRequest"
          :show-file-list="false"
        >
          <svg-icon iconClass="upload" className="icon" />
          <p class="p1">拖拽你的文件到此处</p>
          <p class="p2">或</p>
          <div class="btn">点击上传</div>
        </el-upload>
      </div>
      <div v-show="fileList[0]" class="fileLists">
        <div :style="{'visibility':fileList[0] && fileList[0].fileName ? 'visible' : 'hidden'}" class="item-upload-area">
          <div style="display: flex">
            <img src="@/assets/images/const/xlsx.png" />
            <div class="des">
              <p class="fileName">{{ fileList[0] && fileList[0].fileName }}</p>
              <p class="fileSize">{{ fileList[0] && fileList[0].fileSize }} KB</p>
            </div>
          </div>
          <i class="el-icon-circle-check"></i>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok">确定</div>
    </span>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    feeTypeOptions: {
      type: Array,
      default: () => []
    },
    feeTypeOptions2: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
      excelData: []
    }
  },
  methods: {
    cancel() {
      this.fileList = []
      this.excelData = []
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok', this.excelData)
      this.fileList = []
      this.excelData = []
    },
    httpRequest({ file }) {
      this.fileList = [{
        fileName: file.name,
        fileSize: Math.ceil(file.size / 1024)
      }]
      const reader = new FileReader()
        const that = this
        reader.onload = async function(e) {
          const data = e.target.result
          const wb = XLSX.read(data, { type: 'binary' })
          const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          const newData = jdata
            .map((a) => {
              const newKey = Object.keys(a).sort()
              const newObj = {}
              newKey.forEach((b, i) => {
                newObj[newKey[i].toLowerCase()] = a[newKey[i]]
              })
              return newObj
            })
            .map((a) => {
              const feeTypeId = that.feeTypeOptions.find(b => b.label === a['费用大类*'])?.value
              const feeNameId = that.feeTypeOptions2.find(b => b.label === a['费用细类*'])?.value
              const feeNameList = that.feeTypeOptions2.filter(
                (b) => feeTypeId === b.feeTypeId
              )
              const feeNameValues = feeNameList.find((b) => b.id === feeNameId)
              const unit = feeNameValues.unit
              const description = feeNameValues.description
              return {
                feeTypeId: feeTypeId || '',
                feeNameId: feeNameId || '',
                unitPrice: a['单价*'] || '',
                autoTrigger: a['自动触发'] === '是',
                warehouseCode: '',
                templatePriceType: 1,
                remarks: a['备注'] || '',
                feeNameList,
                unit: unit || '',
                description: description || ''
              }
            })
            that.excelData = newData
        }
        reader.readAsBinaryString(file)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
    margin-right: 16px;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
  }
}
.upload {
  width: 400px;
  height: 200px;
  background: #F7F7F7;
  border-radius: 6px 6px 6px 6px;
  border: 0;
  margin-top: 20px;
  .icon {
    font-size: 40px;
    margin: 26px auto 0;
    color: #2395F3;
  }
  .p1, .p2 {
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    text-align: center;
    margin: 10px auto 0;
    box-sizing: border-box;
  }
  /deep/ .upload-demo {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload-dragger {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload-dragger {
    background: #F7F7F7;;
    border: 0;
  }
}
.fileLists {
    width: 400px;
    height: 64px;
    padding: 12px 24px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .icon {
      color: #b8c3de;
      line-height: 40px;
      cursor: pointer;
    }
    .file {
      display: flex;
    }
  }
.des {
  margin-left: 16px;
}
.fileName {
  width: auto;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #25396f;
  line-height: 19px;
}
.fileSize {
  width: auto;
  margin-top: 5px;
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #7c8db5;
  line-height: 16px;
}
.item-upload-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 18px;
      color: #B8C3DE;
      cursor: pointer;
  }
}
.el-icon-circle-check {
  color: #35BA83 !important;
}
</style>
