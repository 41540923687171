<template>
  <el-dialog
    v-drag
    title="新增跟进术语"
    :visible.sync="dialogVisible"
    width="440px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="4%"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>类别</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              style="width: 100%"
              placeholder="请选择类别"
            >
              <el-option label="清关" :value="0"></el-option>
              <el-option label="提拆交" :value="1"></el-option>
              <el-option label="查验跟进" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>CODE</label>
          <el-form-item prop="code">
            <el-input
              v-model="form.code"
              :disabled="form.id"
              oninput="value=value.replace(/^\.+|[^\w.]/g,'')"
              :maxlength="6"
              placeholder="请输入CODE码"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>标题</label>
          <el-form-item prop="title">
            <el-input v-model="form.title" placeholder="请输入标题"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>内容</label>
          <el-form-item prop="body">
            <el-input v-model="form.body" placeholder="请输入内容"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>权限</label>
          <el-form-item prop="body">
            <el-select
              v-model="form.permission"
              style="width: 320px"
              placeholder="请选择提单分类"
            >
              <el-option label="所有" :value="0"></el-option>
              <el-option label="对内" :value="1"></el-option>
              <el-option label="对外" :value="2"></el-option>
              <el-option label="财务相关" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item form-item2">
          <label class="lable"><span class="red">* </span>标签颜色</label>
          <el-form-item prop="colorArgb">
            <el-popover placement="bottom-start" width="294" trigger="click">
              <div slot="reference" class="color-picker">
                <div
                  class="select-color"
                  :style="{
                    background: form.colorArgb,
                  }"
                >
                  <i
                    :style="{ color: form.colorArgb ? '#ffffff' : '' }"
                    class="el-icon-edit-outline"
                  >
                  </i>
                </div>
              </div>
              <div class="color-list">
                <div
                  v-for="(item, index) in colorList"
                  :key="item.id"
                  :class="['color-item', active === index ? 'active' : '']"
                  :style="{ background: item.dbValue }"
                  @click="selectColor(item, index)"
                >
                  <img
                    v-if="active === index"
                    src="@/assets/images/bill/gou.png"
                    alt=""
                  />
                </div>
              </div>
            </el-popover>
          </el-form-item>
        </div>
        <div class="form-item" style="display: flex">
          <div class="form-item form-item2">
            <label class="lable"><span class="red">* </span>状态</label>
            <el-form-item prop="isActived">
              <el-switch
                v-model="form.isActived"
                active-color="#2395F3"
                inactive-color="#f1f1f1"
                :active-text="form.isActived ? '开启' : '禁用'"
              >
              </el-switch>
            </el-form-item>
          </div>
          <div class="form-item form-item2">
            <label class="lable"><span class="red">* </span>是否可编辑</label>
            <el-form-item prop="isEnable">
              <el-switch
                v-model="form.isEnable"
                active-color="#2395F3"
                inactive-color="#f1f1f1"
                :active-text="form.isEnable ? '可编辑' : '不可编辑'"
              >
              </el-switch>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel('form')">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { saveMBLFollowUpTerminology } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    colorList: {
      type: Array,
      require: true
    },
    rowData: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      form: {
        code: '',
        type: '',
        title: '',
        body: '',
        colorArgb: null,
        isActived: true,
        isEnable: true
      },
      rules: {
        type: [{ required: true, message: '请选择类别', trigger: 'change' }],
        code: [{ required: true, message: '请输入code码', trigger: 'blur' }],
        colorArgb: [
          { required: true, message: '请选择标签颜色', trigger: 'change' }
        ],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        body: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      active: null
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form = {
          ...this.rowData
        }
      } else {
        this.form = {
          code: '',
          type: '',
          title: '',
          body: '',
          colorArgb: null,
          isActived: true,
          permission: 0,
          isEnable: true
        }
      }
    }
  },
  methods: {
    cancel() {
      this.active = null
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!(this.form.code.length >= 3 && this.form.code.length <= 6)) {
            this.$message.error('code码最少三位，最多6位')
            return
          }
          saveMBLFollowUpTerminology(this.form).then((res) => {
            if (res.success) {
              if (this.form.id) {
                this.$message.success('修改成功')
              } else {
                this.$message.success('新增成功')
              }
              this.$emit('ok')
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    selectColor(color, index) {
      this.active = index
      this.form.colorArgb = color.dbValue
    }
  }
}
</script>

<style lang="less" scoped>
.el-popover {
  .color-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 0;
    box-sizing: border-box;
  }
  .color-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: 100%;
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    .color-picker {
      width: 120px;
      height: 60px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      border: 1px solid #eeeeee;
      padding: 10px;
      box-sizing: border-box;
      .select-color {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 4px 4px;
      }
      .el-icon-edit-outline {
        color: #cdcdcd;
        font-size: 20px;
      }
    }
  }
  .form-item2 {
    width: 50%;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

/deep/ .el-color-picker {
  .el-color-picker__trigger {
    width: 120px;
    height: 60px;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
