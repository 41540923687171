var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "opt" }, [
        _c("div", { staticClass: "tab" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "YXJ",
                  expression: "'YXJ'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 0 },
              on: {
                click: function ($event) {
                  return _vm.changeType(0, "YXJ")
                },
              },
            },
            [_vm._v("优先级")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "ZLCYSZ",
                  expression: "'ZLCYSZ'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 1 },
              on: {
                click: function ($event) {
                  return _vm.changeType(1, "ZLCYSZ")
                },
              },
            },
            [_vm._v("重量差异设置")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "GJJLSY",
                  expression: "'GJJLSY'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 2 },
              on: {
                click: function ($event) {
                  return _vm.changeType(2, "GJJLSY")
                },
              },
            },
            [_vm._v("跟进记录术语")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "KJYY",
                  expression: "'KJYY'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 3 },
              on: {
                click: function ($event) {
                  return _vm.changeType(3, "KJYY")
                },
              },
            },
            [_vm._v("扣件原因")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "TDJGMB",
                  expression: "'TDJGMB'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 4 },
              on: {
                click: function ($event) {
                  return _vm.changeType(4, "TDJGMB")
                },
              },
            },
            [_vm._v("提单价格模板")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "CYCXX",
                  expression: "'CYCXX'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 5 },
              on: {
                click: function ($event) {
                  return _vm.changeType(5, "CYCXX")
                },
              },
            },
            [_vm._v("查验场信息")]
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.newAdd,
                expression: "newAdd",
              },
            ],
            staticClass: "btn flex_c_c",
            attrs: { "v-permissson": _vm.newAdd },
            on: { click: _vm.newadd },
          },
          [
            _c("svg-icon", { attrs: { className: "icon", iconClass: "add" } }),
            _vm._v("新增 "),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0 && _vm.active !== 1
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                  isPager: _vm.active !== 5,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "colorArgb",
                      fn: function (row) {
                        return [
                          _vm.active === 0
                            ? _c("div", {
                                staticClass: "colorArgb",
                                style: { background: row.scope.colorArgb },
                              })
                            : _vm._e(),
                          _vm.active === 2
                            ? _c("div", { staticClass: "colorbox" }, [
                                _c("div", {
                                  staticClass: "colorArgb2",
                                  style: { background: row.scope.colorArgb },
                                }),
                                _c(
                                  "div",
                                  { style: { color: row.scope.colorArgb } },
                                  [_vm._v("示例字体颜色")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "url",
                      fn: function (row) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                href: row.scope.url,
                                target: "_blank",
                                type: "primary",
                              },
                            },
                            [_vm._v(_vm._s(row.scope.url))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "pickUpTypeName",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "pickUpType",
                                "flex_c_c",
                                `pickUpType${row.scope.pickUpType}`,
                              ],
                            },
                            [_vm._v(_vm._s(row.scope.pickUpTypeName))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === 0,
                                  expression: "active === 0",
                                },
                              ],
                            },
                            [
                              _c("el-switch", {
                                directives: [
                                  {
                                    name: "permissson",
                                    rawName: "v-permissson",
                                    value: "able_a",
                                    expression: "'able_a'",
                                  },
                                ],
                                attrs: {
                                  "active-color": "#2395F3",
                                  "inactive-color": "#f1f1f1",
                                  "active-text": row.scope.isActived
                                    ? "启用"
                                    : "禁用",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleSwitch(row.scope)
                                  },
                                },
                                model: {
                                  value: row.scope.isActived,
                                  callback: function ($$v) {
                                    _vm.$set(row.scope, "isActived", $$v)
                                  },
                                  expression: "row.scope.isActived",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === 2,
                                  expression: "active === 2",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update_b",
                                      expression: "'update_b'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow2(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "delete_b",
                                      expression: "'delete_b'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRow2(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === 3,
                                  expression: "active === 3",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update_c",
                                      expression: "'update_c'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "delete_c",
                                      expression: "'delete_c'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRow(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === 4,
                                  expression: "active === 4",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "detail_d",
                                      expression: "'detail_d'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detailRow4(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update_d",
                                      expression: "'update_d'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow4(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.active === 5,
                                  expression: "active === 5",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update_e",
                                      expression: "'update_e'",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow3(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4149505385
                ),
              })
            : _vm._e(),
          _vm.active === 1
            ? _c("div", { staticClass: "weight" }, [
                _c("div", { staticClass: "weight-header" }, [
                  _c(
                    "div",
                    { staticClass: "weight-title" },
                    [
                      _c("img", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          src: require("@/assets/images/bill/share.png"),
                        },
                      }),
                      _c("span", [_vm._v("差异率")]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(" 提单重量与绑定订单重量差异率"),
                              _c("br"),
                              _vm._v("超过设定值，提单确认时提示 "),
                            ]
                          ),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/table/question.png"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "permissson",
                        rawName: "v-permissson",
                        value: "update",
                        expression: "'update'",
                      },
                    ],
                    staticClass: "el-icon-edit-outline",
                    attrs: { title: "重量差异设置" },
                    on: { click: _vm.showWeightSet },
                  }),
                ]),
                _c("div", { staticClass: "weight-data" }, [
                  _vm._v(_vm._s(_vm.difference) + "%"),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("add-priority-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible, colorList: _vm.colorList },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
      _c("add-follow-term-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          colorList: _vm.colorList,
          rowData: _vm.rowData,
        },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
      _c("weight-difference-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible3, typeId: _vm.typeId },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
      _c("AddHoldReasonDialog", {
        attrs: { dialogVisible: _vm.dialogVisible4, rowData: _vm.rowData },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
      _c("InspectionSiteDialog", {
        attrs: { dialogVisible: _vm.dialogVisible5, rowData: _vm.rowData },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
      _c("AddPriceTemplateDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible6,
          rowData: _vm.rowData,
          shipOptions: _vm.shipOptions,
          airOptions: _vm.airOptions,
          feeTypeOptions: _vm.feeTypeOptions,
          feeTypeOptions2: _vm.feeTypeOptions2,
          temTitle: _vm.temTitle,
        },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }