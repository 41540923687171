<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="tab">
        <span
          v-permissson="'YXJ'"
          class="tabItem"
          :class="{ active: active === 0 }"
          @click="changeType(0, 'YXJ')"
        >优先级</span
        >
        <span
          v-permissson="'ZLCYSZ'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1, 'ZLCYSZ')"
        >重量差异设置</span
        >
        <span
          v-permissson="'GJJLSY'"
          class="tabItem"
          :class="{ active: active === 2 }"
          @click="changeType(2, 'GJJLSY')"
        >跟进记录术语</span
        >
        <span
          v-permissson="'KJYY'"
          class="tabItem"
          :class="{ active: active === 3 }"
          @click="changeType(3, 'KJYY')"
        >扣件原因</span
        >
        <span
          v-permissson="'TDJGMB'"
          class="tabItem"
          :class="{ active: active === 4 }"
          @click="changeType(4, 'TDJGMB')"
        >提单价格模板</span
        >
        <span
          v-permissson="'CYCXX'"
          class="tabItem"
          :class="{ active: active === 5 }"
          @click="changeType(5, 'CYCXX')"
        >查验场信息</span
        >
      </div>
      <div
        v-show="newAdd"
        :v-permissson="newAdd"
        class="btn flex_c_c"
        @click="newadd"
      >
        <svg-icon className="icon" iconClass="add" />新增
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0 && active !== 1"
        :key="active"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        :isPager="active !== 5"
        @handlePageChange="handlePageChange"
      >
        <!-- <template slot="type" slot-scope="row">
          {{ row.scope.type ? "提拆交" : "清关" }}
        </template> -->
        <template slot="colorArgb" slot-scope="row">
          <div
            v-if="active === 0"
            class="colorArgb"
            :style="{ background: row.scope.colorArgb }"
          ></div>
          <div v-if="active === 2" class="colorbox">
            <div
              class="colorArgb2"
              :style="{ background: row.scope.colorArgb }"
            ></div>
            <div :style="{ color: row.scope.colorArgb }">示例字体颜色</div>
          </div>
        </template>
        <template slot="url" slot-scope="row">
          <el-link :href="row.scope.url" target="_blank" type="primary">{{row.scope.url}}</el-link>
        </template>
        <template slot="pickUpTypeName" slot-scope="row">
          <div :class="['pickUpType', 'flex_c_c', `pickUpType${row.scope.pickUpType}`]">{{ row.scope.pickUpTypeName }}</div>
        </template>
        <template slot="opt" slot-scope="row">
          <div v-show="active === 0">
            <el-switch
              v-model="row.scope.isActived"
              v-permissson="'able_a'"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
              :active-text="row.scope.isActived ? '启用' : '禁用'"
              @change="handleSwitch(row.scope)"
            >
            </el-switch>
          </div>
          <div v-show="active === 2">
            <el-button v-permissson="'update_b'" type="text" size="small" @click="updateRow2(row.scope)">修改</el-button>
            <el-button v-permissson="'delete_b'" type="text" size="small" @click="deleteRow2(row.scope)">删除</el-button>
          </div>
          <div v-show="active === 3">
            <el-button v-permissson="'update_c'" type="text" size="small" @click="updateRow(row.scope)">修改</el-button>
            <el-button v-permissson="'delete_c'" type="text" size="small" @click="deleteRow(row.scope)">删除</el-button>
          </div>
          <div v-show="active === 4">
            <el-button v-permissson="'detail_d'" type="text" size="small" @click="detailRow4(row.scope)">详情</el-button>
            <el-button v-permissson="'update_d'" type="text" size="small" @click="updateRow4(row.scope)">修改</el-button>
          </div>
          <div v-show="active === 5">
            <el-button v-permissson="'update_e'" type="text" size="small" @click="updateRow3(row.scope)">修改</el-button>
          </div>
        </template>
      </BaseTable>
      <div v-if="active === 1" class="weight">
        <div class="weight-header">
          <div class="weight-title">
            <img
              src="@/assets/images/bill/share.png"
              style="margin-right: 10px"
            />
            <span>差异率</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                提单重量与绑定订单重量差异率<br />超过设定值，提单确认时提示
              </div>
              <img src="@/assets/images/table/question.png" />
            </el-tooltip>
          </div>
          <i
            v-permissson="'update'"
            class="el-icon-edit-outline"
            title="重量差异设置"
            @click="showWeightSet"
          ></i>
        </div>
        <div class="weight-data">{{ difference }}%</div>
      </div>
    </div>
    <add-priority-dialog
      :dialogVisible="dialogVisible"
      :colorList="colorList"
      @cancel="cancel"
      @ok="ok"
    ></add-priority-dialog>
    <add-follow-term-dialog
      :dialogVisible="dialogVisible2"
      :colorList="colorList"
      :rowData="rowData"
      @cancel="cancel"
      @ok="ok"
    ></add-follow-term-dialog>
    <weight-difference-dialog
      :dialogVisible="dialogVisible3"
      :typeId="typeId"
      @cancel="cancel"
      @ok="ok"
    ></weight-difference-dialog>
    <AddHoldReasonDialog
      :dialogVisible="dialogVisible4"
      :rowData="rowData"
      @cancel="cancel"
      @ok="ok"
    ></AddHoldReasonDialog>
    <InspectionSiteDialog
      :dialogVisible="dialogVisible5"
      :rowData="rowData"
      @cancel="cancel"
      @ok="ok"></InspectionSiteDialog>
    <AddPriceTemplateDialog
      :dialogVisible="dialogVisible6"
      :rowData="rowData"
      :shipOptions="shipOptions"
      :airOptions="airOptions"
      :feeTypeOptions="feeTypeOptions"
      :feeTypeOptions2="feeTypeOptions2"
      :temTitle="temTitle"
      @cancel="cancel"
      @ok="ok"
    ></AddPriceTemplateDialog>
  </div>
</template>

<script>
import { permissions } from '@/utils/common'
const BaseTable = () => import('@/components/table/BaseTable')
import AddPriorityDialog from './components/AddPriorityDialog.vue'
import AddFollowTermDialog from './components/AddFollowTermDialog.vue'
import WeightDifferenceDialog from './components/WeightDifferenceDialog.vue'
import AddHoldReasonDialog from './components/AddHoldReasonDialog.vue'
import InspectionSiteDialog from './components/InspectionSiteDialog.vue'
import AddPriceTemplateDialog from './components/AddPriceTemplateDialog.vue'
import {
  getMBLPriorityList,
  getMBLFollowUpTerminologyList,
  switchMBLFollowUpTerminology,
  switchMBLPriority,
  getHoldReasonPages,
  deleteHoldReason,
  inspectionSiteList,
  getMBLPriceTemplate
} from '@/api/bill'
import { getSysParams } from '@/api/system'
import { getFeeTypeList, getFeeNamePageList } from '@/api/fee'
import { myMixins } from '@/mixins'
export default {
  name: 'SetBill',
  components: {
    BaseTable,
    AddPriorityDialog,
    AddFollowTermDialog,
    WeightDifferenceDialog,
    AddHoldReasonDialog,
    InspectionSiteDialog,
    AddPriceTemplateDialog
  },
  mixins: [myMixins],
  data() {
    return {
      active: 0,
      activeCode: '',
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      height: 0,
      tableData: {
        columns: [],
        total: 0
      },
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      difference: '',
      typeId: 0,
      colorList: [],
      shipOptions: [],
      airOptions: [],
      feeTypeOptions: [],
      feeTypeOptions2: [],
      rowData: {},
      temTitle: '新增价格模板'
    }
  },
  computed: {
    fileds() {
      const obj = {
        0: [
          {
            filed: 'type',
            label: '类别',
            isCustom: true
          },
          {
            filed: 'contents',
            label: '标签内容'
          },
          {
            filed: 'colorArgb',
            label: '标签颜色',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            isCustom: true
          }
        ],
        2: [
          {
            filed: 'type',
            label: '类别',
            formatter: row => {
              // <el-option label="所有" :value="0"></el-option>
              // <el-option label="对内" :value="1"></el-option>
              // <el-option label="对外" :value="2"></el-option>
              // <el-option label="财务相关" :value="3"></el-option>
              const obj = {
                0: '清关',
                1: '提拆交',
                2: '查验跟进'
              }
              return obj[row.permission]
            }
          },
          {
            filed: 'title',
            label: '标题',
            fixed: 'right'
          },
          {
            filed: 'body',
            label: '内容',
            fixed: 'right'
          },
          {
            filed: 'code',
            label: '代码',
            fixed: 'right'
          },
          {
            filed: 'permission',
            label: '权限',
            fixed: 'right',
            formatter: row => {
              // <el-option label="所有" :value="0"></el-option>
              // <el-option label="对内" :value="1"></el-option>
              // <el-option label="对外" :value="2"></el-option>
              // <el-option label="财务相关" :value="3"></el-option>
              const obj = {
                0: '所有',
                1: '对内',
                2: '对外',
                3: '财务相关'
              }
              return obj[row.permission]
            }
          },
          {
            filed: 'colorArgb',
            label: '字体颜色',
            fixed: 'right',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            fixed: 'right',
            isCustom: true
          }
        ],
        3: [
          {
            filed: 'typeName',
            label: '类别',
            width: 160
          },
          {
            filed: 'holdReason',
            label: '原因',
            width: 780
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: 140
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: 150,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: 140
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: 150,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'opt',
            label: '操作',
            isCustom: true,
            fixed: 'right'
          }
        ],
        4: [
          {
            filed: 'name',
            label: '模板名称',
            width: 314
          },
          {
            filed: 'mblTypeName',
            label: '提单类型',
            width: 185
          },
          {
            filed: 'pickUpTypeName',
            label: '处理方式',
            width: 185,
            isCustom: true
          },
          {
            filed: 'transportName',
            label: '港口/机场',
            width: 185
          },
          {
            filed: 'saleId',
            label: '类型',
            width: 120,
            formatter: (row) =>
              row.saleId === '0' || row.saleId === 0 ? '后台模板' : '销售模板'
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: 120
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: 150,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: 120
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: 150,
            formatter: (row) =>
              row.updatedOn
                ? row.updatedOn.slice(0, row.updatedOn.length - 3)
                : ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: 160,
            isCustom: true
          }
        ],
        5: [
          {
            filed: 'nameCH',
            label: '查验场名称',
            width: 160
          },
          {
            filed: 'url',
            label: '网站链接',
            width: '',
            isCustom: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: 150,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: 140
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: 150,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'opt',
            label: '操作',
            isCustom: true,
            width: 200,
            fixed: 'right'
          }
        ]
      }
      return obj[this.active]
    },
    newAdd() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '新增')?.code
      return code || ''
    }
  },
  async mounted() {
    const res = await getSysParams({ type: 2 })
    this.colorList = res.data[0].value
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['YXJ', 'ZLCYSZ', 'GJJLSY', 'KJYY', 'TDJGMB', 'CYCXX']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeList.findIndex((a) => childrenCode.includes(a))
      this.activeCode = menu.children[0].code
      this.getList(this.pagerConfig)
    }
  },
  methods: {
    changeType(index, code) {
      this.active = index
      this.activeCode = code
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.getList(param)
      index === 4 && this.getTransportOptions() && this.getFeeTypeList()
    },
    async getTransportOptions() {
      const arr1 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 0,
        type: 1
      })
      const arr2 = await this.$store.dispatch('getHarborDrapDown', {
        portType: 1,
        type: 1
      })
      this.shipOptions = arr2
      this.airOptions = arr1
    },
    async getFeeTypeList() {
      try {
        const res = await getFeeTypeList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.forEach((item) => {
              if (item.id === 11 || item.id === 13) {
                arr.push({
                  value: item.id,
                  label: item.name
                })
              }
            })
          this.feeTypeOptions = arr
          const promise = res.data.map(async(item) => {
            const params = {
              pageIndex: 1,
              pageSize: 1000,
              queryParam: {
                feeTypeId: item.id,
                search: '',
                isActived: '',
                isVisible: true
              }
            }
            let result
            try {
              result = await getFeeNamePageList(params)
            } catch (error) {
              console.error(error)
            }
            return new Promise((resolve) => {
              resolve(result)
            })
          })
          Promise.all(promise).then((res) => {
            const arr = []
            res.forEach((item) => {
              item.data.list.forEach((dataItem) => {
                if (dataItem.unit !== null) {
                  arr.push({
                    ...dataItem,
                    value: dataItem.id,
                    label: dataItem.name
                  })
                }
              })
            })
            this.feeTypeOptions2 = arr
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    newadd() {
      switch (this.active) {
        case 0:
          this.dialogVisible = true
          break
        case 2:
          this.dialogVisible2 = true
          break
        case 3:
          this.dialogVisible4 = true
          break
        case 4:
          this.dialogVisible6 = true
          this.temTitle = '新增价格模板'
          break
        case 5:
          this.dialogVisible5 = true
          break
      }
    },
    cancel() {
      this.dialogVisible = false
      this.dialogVisible3 = false
      this.dialogVisible2 = false
      this.dialogVisible4 = false
      this.dialogVisible5 = false
      this.dialogVisible6 = false
      this.rowData = {}
    },
    ok() {
      this.dialogVisible = false
      this.dialogVisible3 = false
      this.dialogVisible2 = false
      this.dialogVisible4 = false
      this.dialogVisible5 = false
      this.dialogVisible6 = false
      this.rowData = {}
      this.initList()
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params
        }
        let res
        switch (this.active) {
          case 0:
            res = await getMBLPriorityList(params)
            break
          case 1:
            res = await getSysParams({ type: 1 })
            break
          case 2:
            res = await getMBLFollowUpTerminologyList(params)
            this.$store.dispatch('getBillRemarkList')
            break
          case 3:
            res = await getHoldReasonPages(params)
            break
          case 4:
            res = await getMBLPriceTemplate(params)
            break
          case 5:
            res = await inspectionSiteList(params)
            break
        }
        this.loading = false
        if (res.success) {
          if (this.active === 1) {
            this.typeId = res.data[0].value[0].id
            this.difference =
              res.data[0].value[res.data[0].value.length - 1].dbValue
            return
          }
          this.tableData.columns = res.data.list || res.data
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    initList() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    handleSwitch(row, item) {
      let data
      switch (this.active) {
        case 0:
          data = {
            id: row.id
          }
          switchMBLPriority(data).then((res) => {
            if (!res.success) {
              this.$message.error(res.errorMessage)
            }
            this.$store.dispatch('getBillRemarkList')
          })
          break
        case 2:
          data = {
            id: row.id,
            type: item === 'isActived' ? 0 : 1
          }
          switchMBLFollowUpTerminology(data).then((res) => {
            if (!res.success) {
              this.$message.error(res.errorMessage)
            }
            this.$store.dispatch('getBillRemarkList')
          })
          break
      }
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    showWeightSet() {
      this.dialogVisible3 = true
    },
    updateRow(row) {
      this.rowData = row
      this.dialogVisible4 = true
    },
    updateRow2(row) {
      this.rowData = row
      this.dialogVisible2 = true
    },
    updateRow3(row) {
      this.rowData = row
      this.dialogVisible5 = true
    },
    async deleteRow({ id }) {
      try {
        const res = await deleteHoldReason({ id })
        if (res.success) {
          this.getList(this.pagerConfig)
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    detailRow4(row) {
      this.rowData = row
      this.dialogVisible6 = true
      this.temTitle = '模板详情'
    },
    updateRow4(row) {
      this.rowData = row
      this.dialogVisible6 = true
      this.temTitle = '修改价格模板'
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  position: relative;
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabItem {
    padding: 20px;
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    color: #2395f3;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    svg {
      font-size: 20px;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .pickUpType {
    width: 40px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    margin: 0 auto;
  }
  .pickUpType1 {
    background: rgba(35, 149, 243, .2);
    color: #2395F3;
  }
  .pickUpType2 {
    background: rgba(53, 186, 131, .2);
    color: #35BA83;
  }
  .colorArgb {
    width: 80px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    margin: auto;
  }
  .colorbox {
    display: flex;
    justify-content: center;
    align-items: center;
    .colorArgb2 {
      width: 40px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      margin-right: 20px;
    }
  }
  .weight {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 240px;
    background: #f7f7f7;
    border-radius: 20px 20px 20px 20px;
    padding: 0 20px 20px;
    box-sizing: border-box;
    .weight-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .weight-data {
      width: 460px;
      height: 140px;
      line-height: 140px;
      text-align: center;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      font-size: 40px;
      font-weight: 400;
      color: #2395f3;
    }
    .weight-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      color: #2395f3;
      span {
        margin-right: 6px;
      }
    }
    .el-icon-edit-outline {
      font-size: 24px;
      color: #2395f3;
      cursor: pointer;
    }
  }
}
</style>
