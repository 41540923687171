<template>
  <el-dialog
    v-drag
    title="重量差异设置"
    :visible.sync="dialogVisible"
    width="440px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>差异率</label>
          <el-form-item prop="dbValue">
            <el-input
              v-model="form.dbValue"
              placeholder="请输入差异率"
              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            >
              <span slot="suffix" class="suffix">%</span>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { updateParams } from '@/api/system'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      require: true
    }
  },
    data() {
    return {
      form: {
        dbValue: ''
      },
      rules: {
        dbValue: [{ required: true, message: '请输入差异率', trigger: 'blur' }]
      },
      loading: false
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (Number(this.form.dbValue) > 1000) {
            this.$message.warning('差异率不能超过1000')
            return
          }
          this.loading = true
          const data = {
            id: this.typeId,
            type: 1,
            dbValue: Number(this.form.dbValue).toFixed(2),
            description: '重量差异设置'
          }
          updateParams(data).then((res) => {
            if (res.success) {
              this.$message.success('设置成功')
              this.$emit('ok')
              this.loading = false
            } else {
              this.loading = false
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: 100%;
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    .suffix {
      color: #666666;
      margin-right: 5px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

/deep/ .el-color-picker {
  .el-color-picker__trigger {
    width: 120px;
    height: 60px;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
