<template>
  <el-dialog
    v-drag
    :title="temTitle"
    :visible.sync="dialogVisible"
    width="1160px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="3%"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-row>
          <el-col :span="11.5">
            <el-form-item label="模板名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入模板名称"
                style="width: 500px"
                :disabled="temTitle !== '新增价格模板'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12.5">
            <el-form-item
              label="通知邮箱"
              prop="noticeEmail"
              style="margin-left: 20px"
            >
              <el-popover
                v-model="popover"
                popper-class="email-popover"
                placement="bottom"
                width="560"
                :disabled="temTitle === '模板详情' ? true : false"
                :visible-arrow="false"
                trigger="click"
              >
                <div class="email-content">
                  <BaseMutiInput
                    ref="baseMutiInput"
                    v-model="form.noticeEmail"
                    :default="form.notifyEmail"
                  ></BaseMutiInput>
                  <div class="footers">
                    <div class="cancel flex_c_c" @click="cancel1">取消</div>
                    <div class="ok flex_c_c" @click="ok1">确定</div>
                  </div>
                </div>
                <div slot="reference" class="cusInput">
                  <el-tag
                    v-for="tag in form.noticeEmail
                      .filter((a) => a !== '')
                      .slice(0, 2)"
                    :key="tag"
                    type="info"
                    :closable="temTitle !== '模板详情'"
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <div
                    v-if="notifyEmailLength && temTitle !== '模板详情'"
                    class="num-remain flex_c_c"
                  >
                    +{{
                      form.noticeEmail.filter((a) => a !== "").slice(2).length
                    }}
                  </div>
                  <el-popover
                    v-else-if="notifyEmailLength && temTitle === '模板详情'"
                    placement="bottom"
                    width="280"
                    trigger="hover"
                  >
                    <div class="email-box">
                      <div class="email-header flex_c_c">通知邮箱</div>
                      <ul class="email-body">
                        <li
                          v-for="item in form.noticeEmail
                            .filter((a) => a !== '')
                            .slice(2)"
                          :key="item"
                          v-tooltip="238"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                    <div slot="reference" class="num-remain flex_c_c">···</div>
                  </el-popover>
                </div>
              </el-popover>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4.8">
            <el-form-item label="提单类型" prop="mblType">
              <el-select
                v-model="form.mblType"
                placeholder="请选择"
                style="width: 200px"
                :disabled="temTitle !== '新增价格模板'"
                @change="mblTypeChange"
              >
                <el-option label="海运" :value="0"></el-option>
                <el-option label="空运" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="处理方式" prop="pickUpType">
              <el-select
                v-model="form.pickUpType"
                placeholder="请选择"
                style="width: 200px"
                :disabled="temTitle !== '新增价格模板'"
              >
                <el-option label="直送" :value="1"></el-option>
                <el-option label="回仓" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="港口/机场" prop="transportName">
              <el-select
                v-model="form.transportName"
                placeholder="请输入港口/机场"
                style="width: 200px"
                filterable
                :disabled="temTitle !== '新增价格模板'"
              >
                <el-option
                  v-for="item in transportOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="生效日期" prop="effectiveDate">
              <el-date-picker
                v-model="form.effectiveDate"
                type="date"
                placeholder="请选择"
                style="width: 200px"
                value-format="yyyy-MM-dd"
                :disabled="temTitle === '模板详情'"
                :picker-options="pickerOptions1"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4.8">
            <el-form-item label="失效日期" prop="elapsedDate">
              <el-date-picker
                v-model="form.elapsedDate"
                type="date"
                placeholder="请选择"
                style="width: 200px"
                value-format="yyyy-MM-dd"
                :disabled="temTitle === '模板详情'"
                :picker-options="pickerOptions2"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="showBaseTab" class="tab">
          <div class="line"></div>
          <BaseTab
            style="z-index: 1"
            :tabItems="tabItems"
            :propWidth="490"
            :active="active"
            @tabChange="tabChange"
          ></BaseTab>
          <div v-if="active && temTitle === '新增价格模板'" class="basetab_opt flex_c_c">
            <div class="import flex_c_c" @click="importFile">
              <img src="@/assets/images/bill/import.png">
              导入
            </div>
            <div class="download flex_c_c" @click="download">
              <img src="@/assets/images/bill/download-1.png">
              下载模板
            </div>
          </div>
        </div>
        <el-row>
          <div class="data">
            <BaseTable
              :key="active"
              :tableData="{
                ...tableData,
                fileds,
              }"
              height="360"
              :isPager="false"
              :border="true"
            >
              <template slot="opt-header">
                <i
                  class="el-icon-circle-plus-outline"
                  title="新增"
                  @click="add"
                ></i>
              </template>
              <template slot="feeTypeId" slot-scope="row">
                <el-select
                  v-if="temTitle === '新增价格模板'"
                  v-model="row.scope.feeTypeId"
                  placeholder="请选择"
                  filterable
                  @change="feeTypeChaneg(row.tIndex, row.scope)"
                >
                  <el-option
                    v-for="item in feeTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-else>
                  {{ row.scope.feeTypeId | formatterFeeType(feeTypeOptions) }}
                </span>
              </template>
              <template slot="feeNameId" slot-scope="row">
                <el-select
                  v-if="temTitle === '新增价格模板'"
                  v-model="row.scope.feeNameId"
                  placeholder="请选择"
                  filterable
                  @change="feeNameChange(row.scope)"
                >
                  <el-option
                    v-for="item in row.scope.feeNameList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-else>
                  {{
                    row.scope.feeNameId
                      | formatterFeeName(row.scope.feeTypeId, feeTypeOptions2)
                  }}
                </span>
              </template>
              <template slot="warehouseCode" slot-scope="row">
                <el-input
                  v-if="temTitle !== '模板详情'"
                  v-model="row.scope.warehouseCode"
                  placeholder="请输入"
                ></el-input>
                <span v-else>
                  {{ row.scope.warehouseCode }}
                </span>
              </template>
              <template slot="unitPrice" slot-scope="row">
                <el-input
                  v-if="temTitle !== '模板详情'"
                  v-model="row.scope.unitPrice"
                  oninput="value=value.replace(/[^0-9.]/g, '')"
                  placeholder="请输入"
                ></el-input>
                <span v-else>
                  {{ row.scope.unitPrice }}
                </span>
              </template>
              <template slot="autoTrigger" slot-scope="row">
                <el-switch
                  v-if="temTitle !== '模板详情'"
                  v-model="row.scope.autoTrigger"
                  :active-text="row.scope.autoTrigger ? '是' : '否'"
                >
                </el-switch>
                <span v-else>
                  {{ row.scope.autoTrigger ? "是" : "否" }}
                </span>
              </template>
              <template slot="remarks" slot-scope="row">
                <el-input
                  v-if="temTitle === '新增价格模板'"
                  v-model="row.scope.remarks"
                  placeholder="请输入"
                ></el-input>
                <span v-else>
                  {{ row.scope.remarks }}
                </span>
              </template>
              <template slot="opt" slot-scope="row">
                <i
                  class="el-icon-delete"
                  title="删除"
                  @click="deleteRow(row.tIndex)"
                ></i>
              </template>
            </BaseTable>
          </div>
        </el-row>
      </el-form>
    </div>
    <span v-if="temTitle !== '模板详情'" slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
    <ImportPriceTemplateDialog
      :dialogVisible="dialogVisible1"
      :feeTypeOptions="feeTypeOptions"
      :feeTypeOptions2="feeTypeOptions2"
      @cancel="dialogVisible1 = false"
      @ok="ok2"
    ></ImportPriceTemplateDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
const BaseTab = () => import('@/components/tab/BaseTab')
import ImportPriceTemplateDialog from './ImportPriceTemplateDialog'
import { isValidEmail } from '@/utils/validate'
import {
  addMBLPriceTemplate,
  getMBLPriceTemplateDetail,
  updateMBLPriceTemplate
} from '@/api/bill'
import config from '@/utils/config'
export default {
  components: {
    BaseTable,
    BaseMutiInput,
    BaseTab,
    ImportPriceTemplateDialog
  },
  filters: {
    formatterFeeType(v, options) {
      const current = options.find((a) => a.value === v)
      return current.label
    },
    formatterFeeName(v, parentId, options) {
      const newId = parentId.toString()
      const feeNameOptions = options.filter(
        (a) => newId.indexOf(a.feeTypeId.toString()) > -1
      )
      const current = feeNameOptions.find((a) => a.value === v)
      return options.length ? current.name : ''
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    temTitle: {
      type: String,
      default: '新增价格模板'
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    shipOptions: {
      type: Array,
      default: () => []
    },
    airOptions: {
      type: Array,
      default: () => []
    },
    feeTypeOptions: {
      type: Array,
      default: () => []
    },
    feeTypeOptions2: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        name: '',
        noticeEmail: [],
        mblType: '',
        pickUpType: '',
        transportName: '',
        effectiveDate: '',
        elapsedDate: ''
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        noticeEmail: [
          { required: true, message: '请输入通知邮箱', trigger: 'blur' }
        ],
        mblType: [
          { required: true, message: '请选择提单类型', trigger: 'change' }
        ],
        pickUpType: [
          { required: true, message: '请选择处理方式', trigger: 'change' }
        ],
        transportName: [
          { required: true, message: '请选择港口/机场', trigger: 'blur' }
        ],
        effectiveDate: [
          { required: true, message: '请选择生效日期', trigger: 'blur' }
        ],
        elapsedDate: [
          { required: true, message: '请选择失效日期', trigger: 'blur' }
        ]
      },
      popover: false,
      tableData: {
        fileds: [
          {
            filed: 'feeTypeId',
            label: '费用大类',
            isCustom: true,
            resizable: true,
            width: 140
          },
          {
            filed: 'feeNameId',
            label: '费用细类',
            isCustom: true,
            resizable: true,
            width: 140
          }
        ],
        columns: []
      },
      loading: false,
      active: 0,
      tabItems: [
        {
          id: 0,
          name: '提单报价'
        },
        {
          id: 1,
          name: '卡派报价'
        }
      ],
      showBaseTab: false,
      templatePrice1: [],
      templatePrice2: [],
      dialogVisible1: false
    }
  },
  computed: {
    notifyEmailLength() {
      return this.form.noticeEmail.filter((a) => a !== '').slice(2).length
    },
    fileds() {
      const baseTabActive = this.active
      const arr = [...this.tableData.fileds]
      const arr2 = [
        {
          filed: 'unit',
          label: '计算维度',
          resizable: true,
          width: baseTabActive ? 99 : 120,
          formatter: (row) => {
            let str
            switch (row.unit) {
              case 0:
                str = '个'
                break
              case 1:
                str = '分单'
                break
              case 2:
                str = '柜子'
                break
              case 3:
                str = '20GP'
                break
              case 4:
                str = '40HQ'
                break
              case 5:
                str = '45HQ'
                break
              default:
                str = ''
                break
            }
            return str
          }
        },
        {
          filed: 'unitPrice',
          label: '单价(USD)',
          isCustom: true,
          resizable: true,
          width: baseTabActive ? 100 : 140
        },
        {
          filed: 'autoTrigger',
          label: '自动触发',
          isCustom: true,
          resizable: true,
          width: baseTabActive ? 90 : 100
        },
        {
          filed: 'remarks',
          label: '备注',
          isCustom: true,
          resizable: true,
          width: baseTabActive ? 180 : 190
        },
        {
          filed: 'description',
          label: '费用介绍',
          resizable: true,
          formatter: (row) => (row.description ? row.description : '')
        }
      ]
      const obj = {
        filed: 'warehouseCode',
        label: '仓库代码',
        resizable: true,
        width: 91,
        isCustom: true
      }
      if (!baseTabActive || this.form.pickUpType === 1) {
        arr.push(...arr2)
      } else {
        arr.push(obj, ...arr2)
      }
      if (this.temTitle === '新增价格模板') {
        const obj = {
          filed: 'opt',
          label: '',
          isCustom: true,
          resizable: true,
          width: 59
        }
        arr.push(obj)
      }
      return arr
    },
    transportOptions() {
      if (this.temTitle === '新增价格模板') {
        return (
          (this.form.mblType === 0 && this.shipOptions) ||
          (this.form.mblType === 1 && this.airOptions)
        )
      } else {
        return [...this.shipOptions, this.airOptions]
      }
    },
    pickerOptions1() {
      if (this.form.elapsedDate) {
        return {
          disabledDate: (time) => {
            const elapsedDate = new Date(this.form.elapsedDate).getTime()
            return time.getTime() > elapsedDate
          }
        }
      } else {
        return {
          disabledDate: null
        }
      }
    },
    pickerOptions2() {
      if (this.form.effectiveDate) {
        return {
          disabledDate: (time) => {
            const effectiveDate = new Date(this.form.effectiveDate).getTime() - (24 * 60 * 60 * 1000)
            return time.getTime() < effectiveDate
          }
        }
      } else {
        return {
          disabledDate: null
        }
      }
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.temTitle !== '新增价格模板' && this.getList()
      }
    },
    'form.pickUpType'(v) {
      !v && (this.showBaseTab = false)
      !v && (this.active = 0)
      v === 1 && (this.showBaseTab = false)
      v === 1 && (this.active = 0)
      v === 2 && (this.showBaseTab = true)
    },
    active(v) {
      !v && (this.tableData.columns = this.templatePrice1)
      v && (this.tableData.columns = this.templatePrice2)
    }
  },
  methods: {
    tabChange(type) {
      this.active = type.id
    },
    handleClose(tag) {
      this.form.noticeEmail.splice(this.form.noticeEmail.indexOf(tag), 1)
      this.$refs.baseMutiInput.valueArr = [...this.form.noticeEmail, '']
    },
    async getList() {
      try {
        const res = await getMBLPriceTemplateDetail({
          templateId: this.rowData.id
        })
        if (res.success) {
          const noticeEmail = res.data.noticeEmail
          const effectiveDate = this.rowData.effectiveDate
          const elapsedDate = this.rowData.elapsedDate
          this.form = {
            ...this.rowData,
            effectiveDate: effectiveDate.slice(0, effectiveDate.length - 9),
            elapsedDate: elapsedDate.slice(0, elapsedDate.length - 9),
            noticeEmail
          }
          const templatePrice1 = res.data.templateItems.filter(a => a.templatePriceType === 0)
          const templatePrice2 = res.data.templateItems.filter(a => a.templatePriceType === 1)
          this.templatePrice1 = templatePrice1
          this.templatePrice2 = templatePrice2
          this.tableData.columns = templatePrice1
          this.$nextTick(() => {
            this.$refs.baseMutiInput.valueArr = [...this.form.noticeEmail, '']
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    reset() {
      this.$refs['form'].resetFields()
      this.$refs.baseMutiInput.valueArr = ['']
      this.tableData.columns = []
      this.active = 0
      this.templatePrice1 = []
      this.templatePrice2 = []
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (!this.tableData.columns.length) {
            this.$message.error('列表明细至少需要添加一条')
            return
          }
          let flag1 = true
          this.form.noticeEmail.forEach((a) => {
            if (!isValidEmail(a)) flag1 = false
          })
          if (!flag1) {
            this.$message.error('请填写正确格式的邮箱')
            return
          }
          let flag2 = true
          this.tableData.columns.forEach((a) => {
            if (!a.feeNameId) flag2 = false
          })
          if (!flag2) {
            this.$message.error('请选择费用细类')
            return
          }
          this.loading = true
          try {
            const params = {
              ...this.form,
              items: [...this.templatePrice1, ...this.templatePrice2],
              id: this.rowData.id || undefined
            }
            let res
            if (this.temTitle === '新增价格模板') {
              res = await addMBLPriceTemplate(params)
            } else {
              res = await updateMBLPriceTemplate(params)
            }
            this.loading = false
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.reset()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        } else {
          return false
        }
      })
    },
    cancel1() {
      this.form.noticeEmail = []
      this.$refs.baseMutiInput.reset()
      this.popover = false
    },
    ok1() {
      this.popover = false
    },
    ok2(data) {
      this.templatePrice2.push(...data)
      this.tableData.columns = this.templatePrice2
      this.dialogVisible1 = false
    },
    add() {
      const obj = {
        feeTypeId: '',
        feeNameId: '',
        unit: '',
        unitPrice: '',
        autoTrigger: false,
        remarks: '',
        warehouseCode: this.active ? '' : undefined,
        description: '',
        templatePriceType: this.active
      }
      !this.active && this.templatePrice1.push(obj)
      this.active && this.templatePrice2.push(obj)
      !this.active && (this.tableData.columns = this.templatePrice1)
      this.active && (this.tableData.columns = this.templatePrice2)
    },
    feeTypeChaneg(index, row) {
      row.feeNameId = ''
      row.unit = ''
      row.description = ''
      const newId = row.feeTypeId.toString()
      const feeNameList = this.feeTypeOptions2.filter(
        (a) => newId === a.feeTypeId.toString()
      )
      this.tableData.columns[index].feeNameList = feeNameList
    },
    feeNameChange(row) {
      row.unit = row.feeNameList.find((a) => a.id === row.feeNameId).unit
      row.description = row.feeNameList.find(
        (a) => a.id === row.feeNameId
      ).description
    },
    deleteRow(index) {
      this.tableData.columns.splice(index, 1)
    },
    mblTypeChange() {
      this.form.transportName = ''
    },
    importFile() {
      this.dialogVisible1 = true
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=1cce481cb7e441a1af7b7fd5cdec8f5e`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .cusInput {
    display: flex;
    align-items: center;
    width: 560px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    .el-tag {
      height: 30px;
      margin-right: 5px;
      font-size: 14px;
      color: #666666;
    }
    .num-remain {
      width: 40px;
      height: 30px;
      background: #f7f7f7;
      border-radius: 2px 2px 2px 2px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .el-icon-circle-plus-outline {
    font-size: 20px;
    color: #2395f3;
    cursor: pointer;
  }
  .el-icon-delete {
    font-size: 20px;
    color: #2395f3;
    cursor: pointer;
  }
  & /deep/ table {
    td {
      height: 40px;
      padding: 0;
    }
    input {
      border: 0;
    }
  }
  .data {
    border-radius: 4px;
    overflow: hidden;
  }
  .tab {
    width: 100%;
    height: 40px;
    margin: 0 auto 30px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      top: 20px;
    }
    .basetab_opt {
      position: absolute;
      top: 30px;
      right: 0;
      & div {
        height: 30px;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      cursor: pointer;
      }
    }
    .import {
      width: 80px;
      background: #35BA83;
      color: #FFFFFF;
      margin-right: 5px;
      img {
        margin-right: 5px;
      }
    }
    .download {
      width: 100px;
      border: 1px solid #35BA83;
      box-sizing: border-box;
      color: #35BA83;
    }
  }
}
.email-content {
  .footers {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    & div {
      width: 100px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
    .cancel {
      background: #eeeeee;
      margin-right: 16px;
    }
    .ok {
      background: #2395f3;
      color: #ffffff;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
    margin-right: 16px;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
  }
}
</style>
<style lang="less">
.email-popover {
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  margin-top: -40px !important;
}
.email-box {
  width: 240px;
  height: 240px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
}
.email-header {
  width: 100%;
  height: 40px;
  background: #f7f7f7;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
}
.email-body {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
  li {
    width: 238px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
