var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增跟进术语",
        visible: _vm.dialogVisible,
        width: "440px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "4%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("类别"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择类别" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "清关", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "提拆交", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "查验跟进", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("CODE"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.form.id,
                          oninput: "value=value.replace(/^\\.+|[^\\w.]/g,'')",
                          maxlength: 6,
                          placeholder: "请输入CODE码",
                        },
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("标题"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标题" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("内容"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "body" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.form.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "body", $$v)
                          },
                          expression: "form.body",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("权限"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "body" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { placeholder: "请选择提单分类" },
                          model: {
                            value: _vm.form.permission,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "permission", $$v)
                            },
                            expression: "form.permission",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "所有", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "对内", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "对外", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "财务相关", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item form-item2" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("标签颜色"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "colorArgb" } },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            width: "294",
                            trigger: "click",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "color-picker",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "select-color",
                                  style: {
                                    background: _vm.form.colorArgb,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-edit-outline",
                                    style: {
                                      color: _vm.form.colorArgb
                                        ? "#ffffff"
                                        : "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "color-list" },
                            _vm._l(_vm.colorList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  class: [
                                    "color-item",
                                    _vm.active === index ? "active" : "",
                                  ],
                                  style: { background: item.dbValue },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectColor(item, index)
                                    },
                                  },
                                },
                                [
                                  _vm.active === index
                                    ? _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/bill/gou.png"),
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-item form-item2" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("状态"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isActived" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#2395F3",
                              "inactive-color": "#f1f1f1",
                              "active-text": _vm.form.isActived
                                ? "开启"
                                : "禁用",
                            },
                            model: {
                              value: _vm.form.isActived,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isActived", $$v)
                              },
                              expression: "form.isActived",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item form-item2" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("是否可编辑"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isEnable" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#2395F3",
                              "inactive-color": "#f1f1f1",
                              "active-text": _vm.form.isEnable
                                ? "可编辑"
                                : "不可编辑",
                            },
                            model: {
                              value: _vm.form.isEnable,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isEnable", $$v)
                              },
                              expression: "form.isEnable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            {
              staticClass: "cancel flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.cancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }