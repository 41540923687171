<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-form-item prop="nameCH" label="查验场名称">
          <el-input v-model="form.nameCH" style="width: 100%" />
        </el-form-item>
        <el-form-item prop="url" label="网站链接">
          <el-input v-model="form.url" style="width: 100%" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { saveInspectionSite } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        nameCH: '',
        url: ''
      },
      rules: {
        nameCH: [
          { required: true, message: '请输入查验场名称', trigger: 'blur' }
        ],
        url: [{ required: true, message: '请输入网站链接', trigger: 'blur' }]
      },
      loading: false
    }
  },
  computed: {
    title() {
        return this.rowData.id ? '修改查验场信息' : '新增查验场信息'
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.rowData.id) {
          this.form = {
            ...this.rowData
          }
        } else {
          this.form = {
            nameCH: '',
            url: ''
          }
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            const params = {
                nameCH: this.form.nameCH,
                url: this.form.url,
                id: this.form.id || undefined
            }
          saveInspectionSite(params).then((res) => {
            if (res.success) {
                if (this.form.id) {
                    this.$message.success('修改成功')
                } else {
                    this.$message.success('新增成功')
                }
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 480px;
  height: auto;
  margin: 0 auto;
  .form {
    margin-top: 10px;
    &:deep(.el-form-item__label) {
      line-height: 30px;
      font-size: 14px;
    }
    &:deep(.el-form-item) {
      margin-bottom: 10px;
      position: relative;
    }
    overflow: hidden;
  }
  .data {
    width: 100%;
    margin: 10px auto 0;
    height: 240px;
    &:deep(.el-input__inner) {
      border: 0;
    }
    &:deep(.el-table--medium .el-table__cell) {
      padding: 0;
    }
    &:deep(.el-table .cell) {
      padding: 0;
    }
  }
  .fp-cell {
    color: #2395f3;
    cursor: pointer;
    line-height: 40px;
  }
  .icon1 {
    font-size: 20px;
    vertical-align: -5px;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 20px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
