<template>
  <el-dialog
    v-drag
    title="新增优先级"
    :visible.sync="dialogVisible"
    width="440px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>类别</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              style="width: 100%"
              placeholder="请选择类别"
            >
              <el-option label="清关" value="0"></el-option>
              <el-option label="提拆交" value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>标签内容</label>
          <el-form-item prop="contents">
            <el-input
              v-model="form.contents"
              placeholder="请输入标签内容"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item form-item2">
          <label class="lable"><span class="red">* </span>标签颜色</label>
          <el-form-item prop="colorArgb">
            <el-popover placement="bottom-start" width="294" trigger="click">
              <div slot="reference" class="color-picker">
                <div
                  class="select-color"
                  :style="{
                    background: form.colorArgb
                  }"
                >
                  <i
                    :style="{color: form.colorArgb ? '#ffffff' : ''}"
                    class="el-icon-edit-outline">
                  </i>
                </div>
              </div>
              <div class="color-list">
                <div
                  v-for="(item, index) in colorList"
                  :key="item.id"
                  :class="['color-item', active === index ? 'active' : '']"
                  :style="{ background: item.dbValue }"
                  @click="selectColor(item, index)"
                >
                  <img v-if="active === index" src="@/assets/images/bill/gou.png" alt="">
                </div>
              </div>
            </el-popover>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
              :active-text="form.isActived ? '开启' : '禁用'"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel('form')">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { saveMBLPriority } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    colorList: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      form: {
        type: '',
        contents: '',
        colorArgb: '',
        isActived: true
      },
      rules: {
        type: [{ required: true, message: '请选择类别', trigger: 'change' }],
        colorArgb: [
          { required: true, message: '请选择标签颜色', trigger: 'change' }
        ],
        contents: [
          { required: true, message: '请输入标签内容', trigger: 'blur' }
        ]
      },
      active: null
    }
  },
  methods: {
    cancel() {
      this.active = null
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveMBLPriority(this.form).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.$refs[formName].resetFields()
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    selectColor(color, index) {
      this.active = index
      this.form.colorArgb = color.dbValue
    }
  }
}
</script>

<style lang="less" scoped>
.el-popover {
  .color-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 0;
    box-sizing: border-box;
  }
  .color-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: 100%;
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    .color-picker {
      width: 120px;
      height: 60px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      border: 1px solid #eeeeee;
      padding: 10px;
      box-sizing: border-box;
      .select-color {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 4px 4px;
      }
      .el-icon-edit-outline {
        color: #cdcdcd;
        font-size: 20px;
      }
    }
  }
  .form-item2 {
    width: 50%;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

/deep/ .el-color-picker {
  .el-color-picker__trigger {
    width: 120px;
    height: 60px;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
