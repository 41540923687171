<template>
  <el-dialog
    v-drag
    :title="rowData.id ? '修改扣件原因' : '新增扣件原因'"
    :visible.sync="dialogVisible"
    width="600px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules" label-position="top">
        <el-form-item prop="type" label="类别">
          <el-select
            v-model="form.type"
            style="width: 480px"
            placeholder="请选择类别"
          >
            <el-option
              v-for="item in config.billHoldTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="holdReason" label="原因">
          <el-input
            v-model="form.holdReason"
            style="width: 480px"
            placeholder="请输入原因"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/utils/config'
import { addHoldReason, updateHoldReason } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      config,
      form: {
        type: '',
        holdReason: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择类别', trigger: 'change' }
        ],
        holdReason: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.rowData.id) {
          const { type, holdReason } = this.rowData
          this.form = {
            type, holdReason,
            id: this.rowData.id
          }
        } else {
          this.form = {
            type: '',
            holdReason: ''
          }
          this.$refs['form']?.resetFields()
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            let res
            if (this.rowData.id) {
              res = await updateHoldReason(this.form)
            } else {
              res = await addHoldReason(this.form)
            }
            if (res.success) {
              this.$emit('ok')
              this.$message.success(res.errorMessage)
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
